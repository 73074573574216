import { defineStore } from 'pinia'

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const usePlayer = defineStore('player', {
    state: () => {
        return {
            currentState: "stopped", // stopped, playing, playing-yt, paused, paused-yt, buffering, buffering-yt, error
            currentTime: 0.0, // player position in time, -1 for stream
            currentMediaURL: "https://stream.radeo.fr/la-sira.mp3", // holding the URL of the media (stream or audio file) to be played
            duration: -1, // -1 for stream, positive integer (or float ?) for other medias
            ytPlayer: null,
            //currentMediaURL: "https://sounds.cigaloun.fr/20220604-13e_nuit_des_vignerons/20220604-nuit_des_vignerons-itv_godogodom.mp3", // holding the URL of the media (stream or audio file) to be played
        }
    }
  // other options...
})