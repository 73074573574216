<template>
    <div class="fullscreen-img" v-show="fs.display" @click="hideOverlay" ref="overlay">
        <img :src="fs.image?.source_url" loading="lazy" :srcset="fs.image?.srcset">
    </div>
</template>
<style lang="scss" scoped>
.fullscreen-img {
    position: absolute;
    z-index: 10;
    width: calc(100% - 1rem);
    height: 100%;
    //display: none;
    background: rgba(0,0,0,0.50);
    backdrop-filter: blur(6px);
    img{
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        margin: auto;
        position: absolute;
        top: 0; left: 0; bottom: 0; right: 0;
        box-shadow: 0 0 1rem 1rem black;
    }
}


</style>
<script setup>
import { ref } from 'vue'

import { useFullScreen } from '@/stores/fullscreen_state'

// eslint-disable-next-line no-undef
const overlay = ref(null)
const fs = useFullScreen()

function hideOverlay() {
    fs.display=false
}
function hideOnEscape(e) {
    if (e.key == "Escape" && fs.display == true) {
        hideOverlay()
    }
}
document.body.addEventListener('keydown', hideOnEscape);
</script>