<template>
  <LogoBlock/>
  <NavigationBlock/>
  <PlayerBlock/>
  <router-view />
  <FooterBlock/>
  <GDPRBlock v-if="visible"
  @consent="consentCookies"
  @decline="declineCookies"/>
  <FullScreenImg/>
</template>

<style lang="scss">

* {
    box-sizing: border-box;
    scrollbar-width: none;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  position: fixed;
  background-color: #000;
  overscroll-behavior-x: none;

  --bleusira: #8ebfd4;
}

a {
  color: #000;
}

::-webkit-scrollbar {
    display: none;
}

#app {
  //font-family: Avenir, Helvetica, Arial, sans-serif;
  font-family: FGrostek,Helvetica Neue,sans-serif;
  font-weight: 400;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //text-align: center;
  //color: #fff;
  background-color: #000;;
  display: grid;
  grid-template-rows: 3rem 6fr 1fr;
  gap: 0.5rem;
  margin: 0;
  padding: 0.5rem;
  height: 100%;
  transition: 300ms;
  transition-timing-function: ease-in-out;
  }
#app.zoomed {
  grid-template-rows: 3rem 1fr 6fr;
}


.page-block {
  background-color: #fff;
  //color: #000;
  padding: 1rem;
  margin: 0;
}

.dark {
  background-color: #000;
  color: #fff;
  a {
    color: #fff;
  }
  path {
    fill: #fff;
  }
}


//layout for mobile device (default)
.logo-block {
  grid-row: 1;
  grid-column: 1;
  padding: 0;
  display: flex;
  flex-direction: row;
  .logo {
    svg {
      height: 100%;
      //width: 100%;
    }
    flex: 1;
  }
  .burger-menu {
    display: block;
    flex: 0;
  }
  .deployed-burger-menu {
    display: block;
  }
}

.navigation {
  grid-row: 2;
  grid-column: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  // font, text

  a {text-decoration: none;}
  font-weight: 700;
  font-size: 0.9rem;
  
  text-transform: uppercase;
  display: none;
}

.player {
  //grid-column: 3;
  display: none;
}

.left-zone {
  grid-row: 3;
  grid-column: 1;
  overflow-x: hidden;
  overflow-y: scroll;
  font-size: 0.9rem;
  h1 {
    margin: 0 0 0.5rem 0;
    text-transform: uppercase;
    font-size: 2rem;
  }
  h2,h3 {
    margin: 0.2rem 0;
  }
  h3 {
    font-weight: normal;
  }
  p {
    line-height: 1.5;
  }
  a {
    color: var(--bleusira);
    font-weight: 900;
  }
  p:first-of-type {
    text-indent: 2rem;
  }
  ul {
    list-style-type: "- ";
    padding-left: 1rem;
    margin: 0;
  }
  li {
    margin: 0;
    line-height: 1.5;
  }
  img {
    width: 100%;
    height: auto;
  }
  figure.logo img {
    width: auto;
    max-width: 100%;
  }
  .wp-block-buttons.is-content-justification-center {
    justify-content: center;
  }
  .wp-block-buttons>.wp-block-button {
    display: inline-block;
    margin: 0;
  }
  >div>div {
      display: flex;
      gap: var(--wp--style--block-gap, 0.5em);
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
  }
  .wp-block-button{
    background: var(--bleusira);
    padding: 1em;
    a {
      color: #fff;
    }
  }
}

.right-zone {
  grid-row: 2/3;
  grid-column: 1;
}

.footer {
  //grid-column: 1 / 4 ;
  display: none;
}


// layout for computer
@media only screen and (min-width: 1024px) {
  #app {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-template-rows: 1rem 8rem 1rem 1fr 1rem;
    gap: 0 1rem;
    padding: 0 1rem;
  }
  #app.zoomed {
    grid-template-rows: 1rem 8rem 1rem 1fr 1rem;
  }
  .logo-block {
    grid-row: 2/3;
    grid-column: 1;
    display: block;
    .logo svg {
      height: 8rem;
    width: 100%;
    }
    .burger-menu {
      display: none;
    }

    .deployed-burger-menu {
      display: none;
    }
  }

  .navigation {
    grid-row: 2/3;
    grid-column: 2;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .player {
    grid-row: 2/3;
    grid-column: 3;
    display: block;
  }

  .left-zone {
    grid-row: 4/5;
    grid-column: 1;
    .zoom-button {
      display: none;
    }
  }

  .right-zone {
    grid-row: 4/5;
    grid-column: 2 / 4;
  }

  .footer {
    grid-row: 5/6;
    grid-column: 1 / 4 ;
    display: block;
    padding: 0;
  }
}

.right-zone {
  overflow-x: auto;
  overflow-y: hidden;
}
.collection-item-list {
  display: flex;
  height: 100%;
  //column-gap: 1rem;
  align-items: flex-start;
}

@media only screen and (max-width: 1023px) {
    .collection-index-item {
        //max-width: 95%;
        flex: 0 0 auto;
        transform-origin: center center;
        transform: scale(1);
        height: 100%;
        .img-content {
            height: 100%;
            width: fit-content;
            img {
                //height: calc(100vh - 23.5rem);
                max-width: 100%;
                object-fit: contain;
                margin-right: 1rem;
            }
        }
    }
}
@media only screen and (min-width: 1024px) {
    .collection-index-item {
        //max-width: 90%;
        height: 100%;
        img {
            //height: calc(100vh - 15rem);
            object-fit: contain;
            margin-right: 1rem;
        }
    }
}

.full-width-img img {
  width: 100%;
  height: auto;
}

.coll-item-banner span.title p {
    margin-block: 0.5rem;
}

figure {
  margin: 1rem 0;
}
</style>

<script setup>
// @ is an alias to /src
import { useRoute,useRouter } from 'vue-router'
import { watch, ref } from 'vue'
// import { usePage } from '@/stores/page_state'
import { useNav } from '@/stores/nav_store'
import sirapi from '@/sirapi'

import LogoBlock from '@/components/LogoBlock.vue'
import NavigationBlock from '@/components/NavigationBlock.vue'
import PlayerBlock from '@/components/PlayerBlock.vue'
import FooterBlock from '@/components/FooterBlock.vue'
import GDPRBlock from '@/components/GDPRBlock'
import FullScreenImg from "@/components/FullScreenImg";

import Cookies from 'js-cookie'
import { useState } from "vue-gtag-next"

const COOKIE_NAME = 'eu_cookie'

const route = useRoute()
const router = useRouter()
// const pageStore = usePage()
const navStore = useNav()
const { isEnabled, isTracking } = useState()

const visible=ref(false)

const getTheRoute = (theRoute) => {
  switch(theRoute[1]){
  case "home":
  case "legal":
  case "privacy":
    sirapi.getPageChildren(181)
    navStore.basePath = '/'
    break
    case "studio_hub":
    sirapi.getPageChildren(47)
    navStore.basePath = '/studio/'
    break
  case "studio_detail":
    sirapi.getPageChildren(47)
    navStore.basePath = '/studio/'
    break
    case "consulting_hub":
    sirapi.getPageChildren(681)
    navStore.basePath = '/consulting/'
    break
  case "consulting_detail":
    sirapi.getPageChildren(681)
    navStore.basePath = '/consulting/'
    break
  case "galerie_hub":
    navStore.basePath = '/galerie/'
    break
  case "galerie_detail":
    sirapi.getCollection('posts',route.params.lang)
    navStore.basePath = '/galerie/'
    break
  case "radio_hub":
    navStore.basePath = '/radio/'
    break
  case "radio_detail":
    sirapi.getCollection('radio',route.params.lang)
    navStore.basePath = '/radio/'
    break
  }
}
const eu_gdpr = Cookies.get(COOKIE_NAME)
if(eu_gdpr==undefined) {
  visible.value = true
} else {
  if(eu_gdpr){
    isEnabled.value = true
  }
}
//getTheRoute(route.name)

watch(
  () => [route.path, route.name],
  newRoute => {
    if(route.params.lang == ''){
      router.push({name: route.name, params: {...route.params, lang: 'fr' }})
    } else getTheRoute(newRoute)
    //console.log("route:"+route.name)
    //console.log("path:"+route.path)
  }
)

const consentCookies = () => {
  console.debug("Cookies accepted")
  Cookies.set(COOKIE_NAME,true, { expires: 30 })
  isEnabled.value = true
  visible.value = false
  console.debug("istracking :")
  console.debug(isTracking)
}

const declineCookies = () => {
  console.debug("Cookies declined")
  Cookies.set(COOKIE_NAME,false, { expires: 30 })
  isEnabled.value = false
  visible.value = false
  console.debug("istracking :")
  console.debug(isTracking)
}



</script>