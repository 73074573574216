<template>
    <div class="cookie-bar">
        Ce site utilise des cookies nécessaires pour ses fonctionnalités, mais aussi d'autres cookies, optionnels, à des fins statistiques.
        Vous pouvez nous aider à améliorer votre expérence de navigation en acceptant ces cookies, ou utiliser ce site sans laisser de trace en les refusant.
        <button @click="$emit('consent')">Accepter les cookies</button> ou <button @click="$emit('decline')">Refuser les cookies</button>
    </div>
</template>
<style lang="scss">
.cookie-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    background-color: #000;
    color: #fff;
}
</style>