import { defineStore } from 'pinia'

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const usePage = defineStore({
    id: 'pageStore',
    options: {
        state: () => {
            return {
                currentPage: {}, // holds the page information to be displayed on all blocks
                mediaCollection: [], // holds the media collection to be displayed on the main content
                postCollection: []
            }
        }
    }
  // other options...
})