import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import VueGtag from "vue-gtag-next";

const pinia = createPinia()
createApp(App).use(pinia).use(router).use(VueGtag, {
    property: { id: "G-MEGJKE546H" },
    isEnabled: false
  }).mount('#app')
